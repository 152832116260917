import Section from '@rsa-digital/evo-shared-components/components/pdf/components/Section';
import SimpleDivider from '@rsa-digital/evo-shared-components/components/pdf/components/SimpleDivider';
import React from 'react';
import { DefaultTheme } from 'styled-components';
import { ProductFeature } from 'components/QuoteSummary/ProductFeaturesTable';
import { Product } from 'helpers/productHelpers';
import ProductFeaturesTablePdf from './ProductFeaturesTablePdf';
import { Heading2 } from '../typography';

export type ProductFeatureSectionProps = {
  product: Product;
  getApplicableFeatures: () => ProductFeature[];
  plainTextPlaceholderReplacer: (csPlainTextString: string) => string;
  theme: DefaultTheme;
  heading: string;
};

const ProductFeatureSection: React.FC<ProductFeatureSectionProps> = ({
  product,
  getApplicableFeatures,
  plainTextPlaceholderReplacer,
  theme,
  heading,
}) => (
  <>
    <Section>
      <Heading2>{heading}</Heading2>
      <SimpleDivider theme={theme} />
      <ProductFeaturesTablePdf
        theme={theme}
        product={product}
        getApplicableFeatures={getApplicableFeatures}
        plainTextPlaceholderReplacer={plainTextPlaceholderReplacer}
      />
    </Section>
  </>
);

export default ProductFeatureSection;
