import { Text, View } from '@react-pdf/renderer';
import Section from '@rsa-digital/evo-shared-components/components/pdf/components/Section';
import SimpleDivider from '@rsa-digital/evo-shared-components/components/pdf/components/SimpleDivider';
import React, { Fragment } from 'react';
import { DefaultTheme } from 'styled-components';
import { Included, NotIncluded } from 'components/CheckYourDetailsPdf/typography';
import { ProductFeature } from 'components/QuoteSummary/ProductFeaturesTable';
import { Product } from 'helpers/productHelpers';

type ProductFeaturesTablePdfProps = {
  product: Product;
  getApplicableFeatures: () => ProductFeature[];
  plainTextPlaceholderReplacer: (csPlainTextString: string) => string;
  theme: DefaultTheme;
};

const ProductFeaturesTablePdf: React.FC<ProductFeaturesTablePdfProps> = ({
  product,
  theme,
  getApplicableFeatures,
  plainTextPlaceholderReplacer,
}) => (
  <>
    <Section>
      {getApplicableFeatures().map((feature: ProductFeature, index: number) => (
        <View style={{ marginRight: 70 }}>
          <Fragment key={feature.feature_text}>
            {index > 0}
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
              wrap={false}>
              {feature.included_with_products_selector.product_selector.includes(
                product
              ) ? (
                <Included />
              ) : (
                <NotIncluded />
              )}
              <Text style={{ flex: 1 }}>
                {plainTextPlaceholderReplacer(feature.feature_text.replace(/\|/g, ''))}
              </Text>
            </View>
            <SimpleDivider theme={theme} />
          </Fragment>
        </View>
      ))}
    </Section>
  </>
);

export default ProductFeaturesTablePdf;
